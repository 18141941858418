import { AccountModel } from './account.types.ts';
import { CustomerModel } from './customer.types.ts';
import { FileMetadata } from './common.types.ts';
import { CountriesEnumType } from './countries.types.ts';

export enum ConsentFlowType {
  UK_COMMERCIAL_BLANKET = 'gb.commercial.blanket',
  // UK_COMMERCIAL_SPECIFIC = 'gb.commercial.specific', @todo leaving this here for now but not available yet
  UK_RESIDENTIAL_SPECIFIC = 'gb.residential.specific',
  NL_COMMERCIAL_SPECIFIC = 'nl.commercial.specific',
  NL_RESIDENTIAL_SPECIFIC = 'nl.residential.specific',
  ES_RESIDENTIAL_SPECIFIC = 'es.residential.specific',
  ES_COMMERCIAL_SPECIFIC = 'es.commercial.specific',
  FR_COMMERCIAL_SPECIFIC = 'fr.commercial.specific',
  FR_RESIDENTIAL_SPECIFIC = 'fr.residential.specific',
}

export enum ConsentMethod {
  LETTER_OF_AUTHORITY = 'letter-of-authority',
  DIGITAL_CONSENT = 'digital-consent',
  THIRD_PARTY_DIGITAL_CONSENT = 'third-party-digital-consent',
}

export enum ConsentEvidenceType {
  ADDRESS = 'address', // We will check for the address here
  ENERGY_BILL = 'energy-bill', // We will check for the meter number here
  IDENTITY = 'identity', // We will check for identity data
  NOT_SPECIFIED = 'not-specified', // No specific check, just evidence upload for legal reasons
}

export enum ConsentEvidenceStatus {
  PENDING = 'pending',
  MANUAL_REVIEW = 'manual-review',
  AUTOMATIC_APPROVED = 'automatic-approved',
  MANUAL_APPROVED = 'manual-approved',
  REJECTED = 'rejected',
}

export const enum ConsentDataUsageFrance {
  ENERGY_MANAGEMENT = 'energy-management',
  DEMAND_RESPONSE = 'demand-response',
  REAL_ESTATE = 'real-estate',
}

export interface FranceConsentSpecificConfiguration {
  data_usage: ConsentDataUsageFrance;
  country: CountriesEnumType['FR'];
}

export interface HostedConsentModel<
  TContextualData extends Record<string, any> = Record<string, any>,
  TCountrySpecificConfiguration extends Record<string, any> = never,
> {
  id: string;
  object: string;
  account: AccountModel;
  customer: CustomerModel;
  expiry: string;
  redirect_url: string;
  flow_type: ConsentFlowType;
  country_specific_configuration?: TCountrySpecificConfiguration;
  contextual_data?: TContextualData;
  authorization_data?: Record<string, any>;
  consent_method?: ConsentMethod;
  consentee_email?: string;
  consentee_email_note?: string;
  consentee_email_code?: string;
  consentee_email_verified: boolean;
  consentee_job_title?: string;
  consentee_full_name?: string;
  evidence_document?: string;
  evidence_document_metadata?: FileMetadata;
  meter_numbers?: string[];
  meter_country?: string[];
  token: string;
  revoke_token: string;
  renewal_token: string;
  hosted_consent_version?: string;
  created_at: string;
  updated_at?: string;
  revoked_at?: string;
  consented_at?: string;
  deleted_at?: string;
  hosted_consent_link: string;
  evidence: string | null;
  valid: boolean;
}
